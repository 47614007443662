.border-none[data-v-1ccee934]:before {
  display: none;
}
.border-none[data-v-1ccee934]:after {
  display: none;
}
.flex-fix[data-v-1ccee934] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-1ccee934]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-1ccee934] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-1ccee934] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-1ccee934] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-1ccee934] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-1ccee934] {
  *zoom: 1;
}
.clear-fix[data-v-1ccee934]:before,
.clear-fix[data-v-1ccee934]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.coupon-list[data-v-1ccee934] {
  width: 100%;
}
.coupon-list .coupon-item[data-v-1ccee934] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 3rem;
  /* padding-left: 2.4rem;
    padding-right: 2.14rem; */
  background-image: url('../../assets/img_lingquan_bg01@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.coupon-list .coupon-item[data-v-1ccee934] {
    background-image: url('../../assets/img_lingquan_bg01@3x.png');
}
}
.coupon-list .coupon-item .value[data-v-1ccee934] {
  position: absolute;
  top: 0.55rem;
  left: 0.68rem;
  color: var(--main-color);
  line-height: 0.48rem;
  font-weight: bold;
  z-index: 2;
}
.coupon-list .coupon-item .value .sign[data-v-1ccee934] {
  font-size: 0.2rem;
}
.coupon-list .coupon-item .value .num[data-v-1ccee934] {
  font-size: 0.6rem;
}
.coupon-list .coupon-item .limit[data-v-1ccee934] {
  font-size: 0.24rem;
  color: #999;
  line-height: 0.34rem;
  z-index: 2;
}
.coupon-list .coupon-item .content[data-v-1ccee934] {
  position: absolute;
  left: 0.68rem;
  top: 38%;
  overflow: hidden;
}
.coupon-list .coupon-item .content .type[data-v-1ccee934] {
  font-size: 0.28rem;
  color: #333;
  height: 0.3rem;
  line-height: 0.3rem;
}
.coupon-list .coupon-item .content .valid[data-v-1ccee934] {
  font-size: 0.24rem;
  color: #999;
  line-height: 0.3rem;
}
.coupon-list .coupon-item .dashed-line[data-v-1ccee934] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 2.14rem;
  width: 0.02rem;
  height: 1.2rem;
  border-right: 1px dashed var(--main-color);
}
.coupon-list .coupon-item .operate-btn[data-v-1ccee934] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0.46rem;
  width: 1.46rem;
  height: 0.52rem;
  text-align: center;
  border-radius: 0.26rem;
  overflow: hidden;
  z-index: 2;
}
.coupon-list .coupon-item .operate-btn.unaccalimed[data-v-1ccee934],
.coupon-list .coupon-item .operate-btn.unused[data-v-1ccee934] {
  font-size: 0.24rem;
  color: #fff;
  line-height: 0.34rem;
  background: var(--main-color);
}
.coupon-list .coupon-item .operate-btn.used[data-v-1ccee934] {
  font-size: 0.24rem;
  color: var(--main-color);
  line-height: 0.34rem;
  border: 1px solid var(--main-color);
}
.coupon-list .coupon-item .operate-btn.expired[data-v-1ccee934] {
  font-size: 0.24rem;
  color: #ff5252;
  line-height: 0.34rem;
}
.coupon-list .coupon-item .operate-btn.distributed[data-v-1ccee934] {
  font-size: 0.28rem;
  color: #999;
  line-height: 0.4rem;
}
.coupon-list .coupon-item .operate-btn.viewed[data-v-1ccee934] {
  font-size: 0.24rem;
  color: #fff;
  line-height: 0.34rem;
  background: #999;
}
.coupon-list .coupon-item.old .value[data-v-1ccee934] {
  color: #ccc;
}
.coupon-list .coupon-item.old .dashed-line[data-v-1ccee934] {
  border-right: 1px dashed #ccc;
}
.coupon-list .coupon-item.view .value[data-v-1ccee934] {
  color: #999;
}
.coupon-list .coupon-item + .coupon-item[data-v-1ccee934] {
  margin-top: -0.3rem;
}
