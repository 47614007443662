.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.coupons-page {
  /* 列表为空*/
}
.coupons-page header {
  width: 100%;
  height: 0.88rem;
  background: #fff;
  padding: 0 0.8rem 0 0.82rem;
  font-size: 0.28rem;
  color: #999;
}
.coupons-page header .tab-item {
  height: 100%;
  line-height: 0.88rem;
  position: relative;
}
.coupons-page header .select-item {
  font-size: 0.28rem;
  color: var(--main-color);
  font-weight: 500;
}
.coupons-page header .line {
  position: absolute;
  width: 0.5rem;
  height: 0.04rem;
  border-radius: 0.06rem;
  background: var(--main-color);
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.coupons-page .yd-list-donetip {
  font-size: 0.28rem;
  color: #999;
  margin-bottom: 1.88rem;
}
.coupons-page .empty-box {
  height: calc(100vh - 0.88rem);
}
